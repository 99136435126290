import {styled} from "@mui/material";

export const Title = styled('div')({
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: 16,
})

export const InputWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    margin: '-12px 0'
})

export const PickerWrapper = styled("div")({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '23%'
})