import { makeStyles } from 'tss-react/mui';

// 
export const useStyles = makeStyles()({
    dialogContentTitle: {
        fontSize: 19,
        lineHeight: "16px",
        fontWeight: "bold",
        marginBottom: 22
    },
});