import { makeStyles } from 'tss-react/mui';

//
export const useStyles = makeStyles()(() => ({
    title: {
        fontSize: 18,
        fontWeight: "bold",
        textTransform: "capitalize",
        marginRight: 10
    },
}));