import {styled} from "@mui/material";

export const ToggleWrapper = styled("div")({
    color: "#252733",
    fontSize: 14,
    fontWeight: 700,
    textTransform: "uppercase",
})

export const SwitcherLabel = styled('p')({
    fontSize: 14,
    fontWeight: 700
})

export const SwitcherWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: 12
})

export const PickersWrapper = styled("div")({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 24,
    fontSize: 18,
    fontWeight: 700
})