import React from 'react';
import {ReactComponent as CheckboxCircle} from "../../../../../../assets/img/done_icon_black.svg";
import {InfoOutlined} from "@mui/icons-material";
import {IPackageOptions} from "../../../../../../api/types";
import {useTranslation} from "react-i18next";
import {EPackagePricingType} from "../../../../../../store/reducers/appointmentFrameReducer/types";
import {TPackage, TService} from "../types";
import {HtmlTooltip} from "../../../../../../components/styled/HtmlTooltip";

type TIncludedInPackageProps = {
    packages: TPackage[];
    services: TService[];
    setClasses: (id: number, cls: string) => string;
    handleClick: (p: IPackageOptions, pricing?: EPackagePricingType) => () => void;
    isBmWService: boolean;
}

const PackagesServiceRequests: React.FC<React.PropsWithChildren<React.PropsWithChildren<TIncludedInPackageProps>>> =
    ({packages, services, setClasses, isBmWService, handleClick}) => {
    const {t} = useTranslation();
    return <React.Fragment>
        <div className="gray subtitle">{t("Included in package")}</div>
        {packages.map(p => <div className={setClasses(p.id, "gray subtitle")} key={p.id}/>)}
        {services
            .slice()
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .map((s, idx) => {
            const isLast = idx + 1 === services.length;
            const cls = `service${isLast ? ' last' : ''}`;
            return <React.Fragment key={s.id}>
                <div className={`serviceWithInfo${isLast ? ' last' : ''}`} style={isBmWService ? {fontSize: 18} : {}}>
                    {s.description} {s.detailedDescription?.length
                    ? <HtmlTooltip
                        placement="right-end"
                        title={<div dangerouslySetInnerHTML={{__html: s.detailedDescription}}/>}
                    ><InfoOutlined style={{cursor: 'pointer', marginLeft: 6}}/></HtmlTooltip> : null}
                </div>

                {packages.map(p => {
                        const clsx = p.lastIdx === idx ? 'service last' : cls;
                        const wMoreClsx = p.moreIdx?.includes(idx) ? `${clsx} lgray` : clsx;
                        return <div
                            key={p.id}
                            onClick={handleClick(p)}
                            className={setClasses(p.id, wMoreClsx)}>
                            {s.packages.includes(p.id) ?  <CheckboxCircle/> : ""}
                        </div>;
                    }
                )}
            </React.Fragment>;
        })}
    </React.Fragment>;
};

export default PackagesServiceRequests;