import { makeStyles } from 'tss-react/mui';

// 
export const useStyles = makeStyles()(() => ({
    topLineWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gap: 20,
        padding: '13px 17px 0 17px',
        alignItems: 'baseline'
    },
    wrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gap: 20,
        padding: '20px 17px',
    },
    rightPart: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: 20,
    },
    leftPart: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 20,
    },
    totalInfo: {
        fontSize: 20,
        color: "#202021",
    },
    pricesInfo: {
        fontSize: 16,
        color: "#252733",
    }
}));