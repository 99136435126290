import {TFilters} from "../types";

export const initialFilters: TFilters = {
    serviceBook: '',
    name: '',
    role: ''
}

export const scOpensText = 'The Service Center opens at'
export const scClosesText = 'The Service Center closes at'
