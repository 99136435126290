import {styled} from "@mui/material";

export const ContentContainer = styled("div")({
    fontSize: 22,
    textAlign: "center",
    fontWeight: "bold"
});

export const Info = styled("p")({
    fontSize: 12,
    fontWeight: "normal"
})