import {styled} from "@mui/material";

export const SelectsTitle = styled('div')(() => ({
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    fontSize: 20,
    fontWeight: "bold",
    color: "#FFFFFF",
    backgroundColor: "#828282",
    padding: '20px 20px 0 20px',
}));