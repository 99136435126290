import React, {useState, useEffect} from "react";
import {Table} from "../../../../components/tables/Table/Table";
import {IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {MoreHoriz} from "@mui/icons-material";
import {IDealershipGroupExtended} from "../../../../store/reducers/dealershipGroups/types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../store/rootReducer";
import * as dealershipActions from "../../../../store/reducers/dealershipGroups/actions";
import {changePageData, remove as removeDealership} from "../../../../store/reducers/dealershipGroups/actions";
import {useHistory} from "react-router-dom";
import {concatAddress} from "../../../../utils/utils";
import {TableRowDataType} from "../../../../types/types";
import {useConfirm} from "../../../../hooks/useConfirm/useConfirm";
import {usePagination} from "../../../../hooks/usePaginations/usePaginations";

import {useMessage} from "../../../../hooks/useMessage/useMessage";
import {useException} from "../../../../hooks/useException/useException";
import {Routes} from "../../../../routes/constants";
import {authService} from "../../../../api/AuthService/AuthService";

const rowData: TableRowDataType<IDealershipGroupExtended>[] = [
    {val: el => el.name, header: "Dealership name"},
    {val: el => el.countOfServiceCenters.toString(), header: "Service centers", align: "center"},
    {val: el => el.countOfEmployees.toString(), header: "Employees", align: "center"},
    {val: el => concatAddress(el.address), header: "Address"}
];

export const DealershipGroupsTable = () => {
    const {count, data, isLoading} = useSelector((state: RootState) => ({
        count: state.dealershipGroups.paging.numberOfRecords,
        data: state.dealershipGroups.dealershipList,
        isLoading: state.dealershipGroups.loading
    }));

    const {changeRowsPerPage,changePage,pageIndex,pageSize} = usePagination(
        (s: RootState) => s.dealershipGroups.pageData,
        changePageData
    );

    const [editedItem, setEditedItem] = useState<IDealershipGroupExtended|null>(null);
    const [anchorEl, setAnchorEl] = useState<EventTarget&HTMLButtonElement|null>(null);

    const {askConfirm} = useConfirm();
    const dispatch = useDispatch();
    const showError = useException();
    const showMessage = useMessage();
    const history = useHistory();

    useEffect(() => {
        dispatch(dealershipActions.loadAll());
    }, [dispatch]);

    const handleView = ()  => {
        if (!editedItem) return;
        setAnchorEl(null);
        history.push(`${Routes.Admin.DealershipGroups}/${editedItem.id}`);
    };
    const handleRemoveAction = () => {
        setAnchorEl(null);
        askConfirm({
            title: `Please confirm you want to remove dealership group ${editedItem?.name}?`,
            isRemove: true,
            onConfirm: async () => {
                await handleRemove();
            }
        });
    };
    const handleRemove = async () => {
        if (!editedItem) return;
        const d = editedItem
        try {
            await dispatch(removeDealership(d.id));
            showMessage(`Dealership ${d.name} removed`);
        } catch (e) {
            showError(e);
        }
    }

    const handleLogin = async () => {
        setAnchorEl(null);
        try {
            await authService.dealershipLogin(editedItem?.id??0);
            window.location.reload();
        } catch (e) {
            showError(e);
        }
    }

    const closeMenu = () => {
        setEditedItem(null);
        setAnchorEl(null);
    }

    const openMenu = (el: IDealershipGroupExtended) =>
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setEditedItem(el);
        setAnchorEl(e.currentTarget);
    }

    const viewActions = (el: IDealershipGroupExtended) =>
        <IconButton size="small" onClick={openMenu(el)}>
            <MoreHoriz />
        </IconButton>
    ;

    return <>
        <Table<IDealershipGroupExtended>
            data={data}
            noDataTitle="No Dealership Groups are present"
            isLoading={isLoading}
            rowData={rowData}
            onChangePage={changePage}
            onChangeRowsPerPage={changeRowsPerPage}
            count={count}
            page={pageIndex}
            rowsPerPage={pageSize}
            index="id"
            actions={viewActions}
        />
        <Menu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={closeMenu}>
            <MenuItem onClick={handleView}>View</MenuItem>
            <MenuItem onClick={handleLogin}>Login</MenuItem>
            <MenuItem onClick={handleRemoveAction}>
                <Typography color="secondary">Remove</Typography>
            </MenuItem>
        </Menu>
    </>
}