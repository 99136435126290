import { makeStyles } from 'tss-react/mui';

//
export const useStyles = makeStyles()(() => ({
    wrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'start',
        background: '#7898FF',
        color: 'white',
        borderRadius: 4,
        fontWeight: 'bold',
        fontSize: 14,
        margin: 4,
        padding: 4,
    },
}));