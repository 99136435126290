import { PaletteOptions } from '@mui/material/styles';

export const colors: PaletteOptions = {
    primary: {
        main: '#7898FF',
        contrastText: "#FFFFFF"
    },
    secondary: {
        main: "#f50057",
        contrastText: "#FFFFFF"
    },
    background: {
        default: "#F7F8FB",
        paper: "#FFFFFF"
    },
    success: {
        main: '#89E5AB',
        contrastText: "#202021"
    },
    info: {
        main: '#202021',
    },
    divider: "#DADADA",
    error: {
        main: "#FF0000",
        contrastText: "#FFFFFF"
    }
};

