import {styled} from "@mui/material";

export const InputWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: 30
})

export const ZonesWrapper = styled('div')({
    minHeight: 220,
})