import React from 'react';
import {centerProfileRoot} from "../../../utils/constants";
import {TitleContainer} from "../../../components/wrappers/TitleContainer/TitleContainer";

const Integrations = () => {
    return (
        <div>
            <TitleContainer title="Integrations" pad parent={centerProfileRoot}/>
        </div>
    );
};

export default Integrations;