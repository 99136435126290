import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    button: {
        textTransform: 'none',
    },
    tableWrapper: {
        borderRadius: 1,
        margin: 27,
    }
}));