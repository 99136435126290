import {styled} from "@mui/material";

export const SubTitle = styled("div")({
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase'
})

export const Label = styled("div")({
    fontSize: 12,
    fontWeight: 700,
    textTransform: 'uppercase'
})