import {TRole} from "../../../store/reducers/users/types";

export const rolesList: TRole[] = [
    "Owner",
    "Manager",
    "Advisor",
    "Technician",
    "Call Center Rep",
    "Service Director",
    "Call Center Administrator"
]