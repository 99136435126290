import React, {useEffect, useState} from 'react';
import {DialogProps} from "../../../../components/modals/BaseModal/types";
import {BaseModal, DialogActions, DialogContent, DialogTitle} from "../../../../components/modals/BaseModal/BaseModal";
import {Box, Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {EDay, EDemandCategory, IDayOfWeekSetting} from "../../../../store/reducers/pricingSettings/types";
import {SC_UNDEFINED} from "../../../../utils/constants";
import {loadDayOfWeekPricing, setWorkWeekPricing} from "../../../../store/reducers/pricingSettings/actions";
import {mappedDWeekPricingSelector} from "../../../../store/reducers/pricingSettings/selectors";
import {TForm} from "./types";
import {buttons, initialForm} from "./constants";
import {LoadingButton} from "../../../../components/buttons/LoadingButton/LoadingButton";
import {SwitchButtons} from "../../../../components/buttons/SwitchButtons/SwitchButtons";

import {useMessage} from "../../../../hooks/useMessage/useMessage";
import {useException} from "../../../../hooks/useException/useException";
import {useSCs} from "../../../../hooks/useSCs/useSCs";
import dayjs from "dayjs";

export const WorkWeekModal: React.FC<React.PropsWithChildren<React.PropsWithChildren<DialogProps>>> = ({onAction, payload, ...props}) => {
    const [form, setForm] = useState<TForm>(initialForm);
    const [saving, setSaving] = useState<boolean>(false);
    const showError = useException();
    const showMessage = useMessage();
    const dispatch = useDispatch();
    const {selectedSC} = useSCs();
    const demand = useSelector(mappedDWeekPricingSelector);

    useEffect(() => {
        if (props.open) {
            if (selectedSC) {
                dispatch(loadDayOfWeekPricing(selectedSC.id));
            } else {
                setForm(initialForm);
            }
        }
    }, [props.open, dispatch, selectedSC]);

    useEffect(() => {
        setForm({
            ...initialForm,
            ...demand
        })
    }, [demand]);

    const handleSwitch = (idx: EDay) => (t: EDemandCategory) => () => {
        setForm({...form, [idx]: t});
    }

    const handleSave = async () => {
        if (!selectedSC) {
            showError(SC_UNDEFINED);
        } else {
            try {
                setSaving(true);
                await dispatch(setWorkWeekPricing(
                    Object.entries(form).filter(([k, v]) => !isNaN(Number(k))).map(([k, v]) => {
                        return {
                            demandCategory: v,
                            dayOfWeek: Number(k) as EDay,
                            serviceCenterId: selectedSC.id
                        } as IDayOfWeekSetting;
                    }))
                )
                showMessage("Saved");
                setSaving(false);
                props.onClose();
            } catch (e) {
                setSaving(false);
                showError(e);
            }
        }
    }

    const getContent = () => {
        const days = dayjs.weekdays().map((wd, idx) => {
            return <React.Fragment key={wd}>
                <Box component="span" fontWeight="bold">{wd}</Box>
                <Box>
                    <SwitchButtons<number>
                        onClick={handleSwitch(idx as EDay)}
                        active={form[idx as EDay]}
                        buttons={buttons} />
                </Box>
            </React.Fragment>
        });
        const sunday = days[0]
        days.shift();
        days.push(sunday)
        return days;
    }
    return (
        <BaseModal {...props} width={345}>
            <DialogTitle onClose={props.onClose}>Work Week Settings</DialogTitle>
            <DialogContent style={{paddingBottom: 20, paddingTop: 16}}>
                <Box display="grid" gap={'10px'} gridTemplateColumns="1fr 197px">
                    <Box component="span" fontWeight="bold" color={"text.disabled"}>SLOT STARTS</Box>
                    <Box component="span" fontWeight="bold" color={"text.disabled"}/>
                    {getContent()}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="info">Close</Button>
                <LoadingButton
                    loading={saving}
                    onClick={handleSave}
                    color="primary"
                    variant="contained">
                    Save
                </LoadingButton>
            </DialogActions>
        </BaseModal>
    );
};