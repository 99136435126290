import React, {useState} from 'react';
import {Tab} from "@mui/material";
import {TitleContainer} from "../../../components/wrappers/TitleContainer/TitleContainer";
import {TabContext, TabPanel} from "@mui/lab";
import {TabList} from "../../../components/styled/Tabs";
import GeographicZones from "../../../features/admin/PricingMobileService/GeograficZones/GeographicZones";
import GeographicZonesMap from "../../../features/admin/PricingMobileService/GeograficZonesMap/GeographicZonesMap";
import AddEditGeographicZone from "../../../components/modals/admin/EditGeographicZone/AddEditGeographicZone";
import AncillaryPrice from "../../../features/admin/PricingMobileService/AncillaryPrice/AncillaryPrice";
import {servicesRoot} from "../../../utils/constants";
import {useModal} from "../../../hooks/useModal/useModal";

type TTab = {
    id: string;
    label: string;
    component: JSX.Element
}

const MobileService = () => {
    const [selectedTab, selectTab] = useState<string>("0");
    const {onOpen: onAddZoneOpen, onClose: onAddZoneClose, isOpen: isAddZoneOpen} = useModal();

    const tabs: TTab[] = [
        {id: "0", label: "Geographic Zones", component: <GeographicZones onAddZoneOpen={onAddZoneOpen}/>},
        {id: "1", label: "Geographic Zones Map", component: <GeographicZonesMap />},
        // {id: "2", label: "Zone Routing", component: null},
        {id: "3", label: "Convenience Fees", component: <AncillaryPrice />},
    ]

    const handleTabChange = (e: any, value: string) => {
        selectTab(value);
    }

    return <TabContext value={selectedTab}>
            <TitleContainer title="Mobile Service" pad parent={servicesRoot}/>
            <TabList
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleTabChange}
                indicatorColor="primary"
            >
                {tabs.map(t => {
                    return <Tab label={t.label} value={t.id} key={t.id} />;
                })}
            </TabList>
            {tabs.map(t => {
                return <TabPanel
                    style={{width: "100%"}}
                    key={t.id}
                    value={t.id}>
                    {t.component}
                </TabPanel>
            })}
            <AddEditGeographicZone open={isAddZoneOpen} onClose={onAddZoneClose} isEdit={false} serviceType="mobileService"/>
        </TabContext>
};

export default MobileService;