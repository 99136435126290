import {styled} from "@mui/material";

export const TopWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase'
})

export const ButtonsWrapper = styled('div')({
    width: "40%",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})